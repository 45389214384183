import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Card = ({
  title,
  text,
  subtitle,
  sublink,
  blurbs,
  author,
  authorImg,
  date,
  link,
  image,
}) => {
  return (
    <div className="flex flex-col overflow-hidden rounded-lg shadow-lg">
      <div className="flex-shrink-0">
        <Link to={link}>
          <GatsbyImage image={getImage(image)} alt="" />
        </Link>
      </div>
      <div className="flex flex-col justify-between flex-1 p-6 bg-white">
        <div className="flex-1">
          {subtitle && (
            <p className="text-sm font-medium leading-5 text-indigo-600">
              <Link to={sublink} className="hover:underline">
                {subtitle}
              </Link>
            </p>
          )}
          {blurbs &&
            blurbs.map((blurb, i) => {
              return (
                <span className="inline-flex items-center px-2 py-0 mr-3 text-xs font-medium leading-5 text-white bg-gray-500 rounded-full">
                  {blurb}
                </span>
              )
            })}
          <Link to={link} className="block">
            <h3 className="mt-2 text-xl font-semibold leading-7 text-gray-900">
              {title}
            </h3>
            <p className="mt-3 text-base leading-6 text-gray-700">{text}</p>
          </Link>
        </div>
        {author && (
          <div className="flex items-center mt-6">
            <div className="flex-shrink-0">
              <div>
                <GatsbyImage
                  image={getImage(authorImg)}
                  className={`inline-block h-14 w-14 rounded-full`}
                  alt=""
                />
              </div>
            </div>
            <div className="ml-3">
              <p className="text-sm font-medium leading-5 text-gray-900">
                {author}
              </p>
              <div className="flex text-sm leading-5 text-gray-500">
                <time dateTime={date}>{date}</time>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Card
