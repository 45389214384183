import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Container from "../containers/Container"

const CtaSectionWissen = () => {
  const data = useStaticQuery(graphql`
    query {
      wissensdatenbank: file(
        relativePath: { eq: "pages/hintergrundbild_wissensdatenbank.jpg" }
      ) {
        id
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `)

  return (
    <Container>
      <div className="relative mx-auto my-8 bg-gray-800 max-w-7xl">
        <div className="relative h-56 bg-blue-500 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
          <GatsbyImage
            image={getImage(data.wissensdatenbank)}
            className="absolute top-0 left-0 w-full h-full"
            alt="Buch auf blauen Hintergrund"
          />
        </div>
        <div className="relative max-w-screen-xl px-4 py-12 mx-auto sm:px-6 lg:px-8 lg:py-16">
          <div className="md:ml-auto md:w-1/2 md:pl-10">
            <div className="text-base font-semibold leading-6 tracking-wider text-gray-300 uppercase">
              Du bist nicht alleine
            </div>
            <h2 className="mt-2 text-3xl font-extrabold leading-9 tracking-tight text-white sm:text-4xl sm:leading-10">
              Wir sind hier um Dir zu helfen
            </h2>
            <p className="mt-3 text-lg leading-7 text-gray-300">
              Entdecke unser Infocenter. Wir möchten Dich auf dem Laufenden
              halten und beantworten Deine Fragen im FAQ oder im Podcast.
            </p>
            <div className="mt-8">
              <div className="inline-flex rounded-md shadow">
                <Link
                  to="/info/"
                  className="inline-flex items-center justify-center px-5 py-3 text-base font-medium leading-6 text-gray-900 transition duration-150 ease-in-out bg-white border border-transparent rounded-md hover:text-gray-600 focus:outline-none focus:shadow-outline"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="w-5 h-5 mr-2 "
                  >
                    <path d="M10.394 2.08a1 1 0 00-.788 0l-7 3a1 1 0 000 1.84L5.25 8.051a.999.999 0 01.356-.257l4-1.714a1 1 0 11.788 1.838L7.667 9.088l1.94.831a1 1 0 00.787 0l7-3a1 1 0 000-1.838l-7-3zM3.31 9.397L5 10.12v4.102a8.969 8.969 0 00-1.05-.174 1 1 0 01-.89-.89 11.115 11.115 0 01.25-3.762zM9.3 16.573A9.026 9.026 0 007 14.935v-3.957l1.818.78a3 3 0 002.364 0l5.508-2.361a11.026 11.026 0 01.25 3.762 1 1 0 01-.89.89 8.968 8.968 0 00-5.35 2.524 1 1 0 01-1.4 0zM6 18a1 1 0 001-1v-2.065a8.935 8.935 0 00-2-.712V17a1 1 0 001 1z" />
                  </svg>
                  Zur Wissensdatenbank
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default CtaSectionWissen
