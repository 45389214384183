import React from "react"
import Layout from "../components/layouts/Default"
import Header from "../components/common/Header"
import { graphql, Link } from "gatsby"
import Card from "../components/common/Card"
import Reviews from "../components/Reviews"
import CtaSectionInfocenter from "../components/common/CtaSectionInfocenter"
import H1 from "../components/typography/H1"
import H2 from "../components/typography/H2"
import P from "../components/typography/P"
import Container from "../components/containers/Container"

export default function Page({ data, location }) {
  return (
    <Layout
      title="Finanzberater Kassel: Ansprechpartner in Sachen Finanzen"
      description="Wir sind Dein Finanzberater in Kassel der Dich an vorderste Stelle stellt. Wir beraten Dich unabhängig und neutral zu Deinen Finanzfragen."
      location={location}
      imageFacebook={`${data.site.siteMetadata.siteUrl}${data.seoImage.facebook.gatsbyImageData.images.fallback.src}`}
      imageFacebookWidth={data.seoImage.facebook.gatsbyImageData.width}
      imageFacebookHeight={data.seoImage.facebook.gatsbyImageData.height}
      imageTwitter={`${data.site.siteMetadata.siteUrl}${data.seoImage.twitter.gatsbyImageData.images.fallback.src}`}
    >
      <Header
        link="/anfragen/"
        linktext="Jetzt beraten lassen"
        link2="/ueber-uns/"
        link2text="Über uns"
        image={data.header}
        alt="Finanzberater Kassel: Florian Dierda im Beratungsgespräch"
      >
        <H1 className="mt-1 mb-8">
          <span className="text-blue-500">Finanzberater Kassel:</span> Dein
          aufklärender und kompetenter Ansprechpartner in Sachen Finanzen
        </H1>
        <p className="mb-6 text-xl italic leading-7 text-indigo-500 ">
          &bdquo;Finanzplanung - von Menschen für Menschen&ldquo;
        </p>
        <P>
          Du bist individuell – so solltest Du auch behandelt werden. Wir von
          Florian Dierda Finanzdienstleistungen sein Dein{" "}
          <strong>Finanzberater</strong> in <strong>Kassel</strong> und stellen
          Dich an vorderste Stelle. Wir beraten Dich kostenfrei und beantworten
          alle Deine Finanzfragen unabhängig und neutral und unterstützen Dich
          bei folgenden Themen:{" "}
          <Link
            to="/versicherungen/#angebote"
            className="font-medium text-indigo-600 hover:underline"
          >
            Versicherungen
          </Link>
          ,{" "}
          <Link
            to="/finanzierungen/#angebote"
            className="font-medium text-indigo-600 hover:underline"
          >
            Finanzierungen
          </Link>{" "}
          und{" "}
          <Link
            to="/geldanlage/#angebote"
            className="font-medium text-indigo-600 hover:underline"
          >
            Geldanlage
          </Link>
          .
        </P>
      </Header>
      <Container>
        <div className="mb-12 text-center">
          <H2 className="mb-3">Unsere Finanz&shy;dienstleistungen</H2>
          <P className="max-w-xl mx-auto">
            Wir können Dich in vielen Bereichen unterstützen. Nachfolgend
            findest Du eine Auswahl unserer Leistungen.
          </P>
        </div>
        <div className="grid gap-5 mx-auto sm:grid-cols-2 lg:grid-cols-3">
          <Card
            image={data.bu}
            title="Berufs­unfähigkeits­versicherung"
            link="/versicherungen/berufsunfaehigkeitsversicherung/"
            subtitle="Versicherungen"
            sublink="/versicherungen/"
            text="Die Dienst- und Berufs&shy;unfähigkeits&shy;versicherung als individueller Schutz für jeden Erwerbstätigen."
          />

          <Card
            image={data.alter}
            title="Altersvorsorge"
            link="/versicherungen/altersvorsorge/"
            subtitle="Versicherungen"
            sublink="/versicherungen/"
            text="Deine sichere Altersvorsorge: Genieße den Ruhestand in der Sonne."
          />
          <Card
            image={data.kredit}
            title="Privatkredit"
            link="/finanzierungen/privatkredit/"
            subtitle="Finanzierungen"
            sublink="/finanzierungen/"
            text="Wir finden Deinen günstigsten Privatkredit."
          />
          <Card
            image={data.baufi}
            title="Baufinanzierung"
            link="/finanzierungen/baufinanzierung/"
            subtitle="Finanzierungen"
            sublink="/finanzierungen/"
            text="Niedrige Zinsen bei der Immobilienfinanzierung: Wir vergleichen und beraten Dich unabhängig."
          />

          <Card
            image={data.geldanlage}
            title="Allgemeine Geldanlage"
            link="/geldanlage/allgemeine-geldanlage/"
            subtitle="Geldanlage"
            sublink="/geldanlage/"
            text="Geldanlage: Wie Du Dein Geld langfristig vermehrst."
          />
        </div>
      </Container>
      <CtaSectionInfocenter />
      <Reviews />
    </Layout>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        siteUrl
      }
    }
    allSitePage {
      edges {
        node {
          path
        }
      }
    }

    header: file(relativePath: { eq: "headers/dierda_startseite_header.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData
      }
    }

    seoImage: file(
      relativePath: { eq: "headers/dierda_startseite_header.jpg" }
    ) {
      facebook: childImageSharp {
        gatsbyImageData(height: 630, width: 1200)
      }
      twitter: childImageSharp {
        gatsbyImageData(aspectRatio: 1)
      }
    }

    bu: file(
      relativePath: {
        eq: "headers/dierda_berufsunfaehigkeitsversicherung_header.jpg"
      }
    ) {
      id
      childImageSharp {
        gatsbyImageData
      }
    }
    alter: file(
      relativePath: { eq: "headers/dierda_altersvorsorge_header.jpg" }
    ) {
      id
      childImageSharp {
        gatsbyImageData
      }
    }
    kredit: file(
      relativePath: { eq: "headers/dierda_privatkredit_header.jpg" }
    ) {
      id
      childImageSharp {
        gatsbyImageData
      }
    }
    baufi: file(
      relativePath: { eq: "headers/dierda_baufinanzierung_header.jpg" }
    ) {
      id
      childImageSharp {
        gatsbyImageData
      }
    }
    geldanlage: file(
      relativePath: { eq: "headers/dierda_geldanlage-allgemein_header.jpg" }
    ) {
      id
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`
